import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import EditMicroLearn from './EditMicroLearn';
import { saveMicroLearn, isLoading, capabilityThemes } from '../../store/microlearn';

const defaultMicroLearn = {
	title: "",
	summary: "",
	type: "Academic",
	capabilityTheme: capabilityThemes[0].value,
	image: "",
	video: "",
	attachments: []
};

const CreateMicroLearnController = props => {
	return (
        <PageLayout 
            title="Create Resource" 
			loading={props.loading}
            pageContent={
                <EditMicroLearn 
                    canEdit
                    microLearn={defaultMicroLearn}
					saveMicroLearn={props.saveMicroLearn}
					loading={props.loading}
                    isNew
                />
	        } 
            breadcrumbs={[
                { label: "Resources",  path: "/resources" }, 
                "Create Resource"
            ]} 
        />
    );
};

CreateMicroLearnController.propTypes = { 
	saveMicroLearn: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveMicroLearn: (microLearn, imageFile, videoFile, newFiles) => dispatch(saveMicroLearn(microLearn, imageFile, videoFile, newFiles))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMicroLearnController);
