import {
	combineReducers,
} from 'redux';
import { configureStore as reduxConfigureStore } from "@reduxjs/toolkit";
import { thunk } from 'redux-thunk'
import { createReduxHistoryContext } from "redux-first-history";
import audits from './audits';
import context from './context';
import layout from './layout';
import loading from './loading';
import modal from './modal';
import notifications from './notifications';
import tenants from './tenants';
import users from './users';
import profiles from './profiles';
import problems from './problems';
import problemChat from './problemChat';
import positions from './positions';
import departments from './departments';
import dashboard from './dashboard';
import settings from './settings';
import events from './events';
import userNotifications from './userNotifications';
import offers from './offers';
import features from './features';
import ipRegister from './ipRegister';
import courses from './courses';
import cloCourses from './cloCourses';
import passport from './passport';
import academics from './academics';
import communities from './communities';
import activities from './activities';
import tools from './tools';
import competencies from './competencies';
import unitsOfCompetency from './unitsOfCompetency';
import theme from './theme';
import themes from './themes';
import organisations from './organisations';
import skillsProfiles from './skillsProfiles';
import roles from './roles';
import reports from './reports';
import centres from './centres';
import resources from './resources';
import userCommunities from './userCommunities';
import discussion from './discussion';
import channels from './channels';
import badges from './badges';
import frameworks from './frameworks';
import mentors from './mentors';
import menu from './menu';
import students from './students';
import studentProjects from './studentProjects';
import insights from './insights';
import experts from './experts';
import mentees from './mentees';
import messages from './messages';
import businessProfiles from './businessProfiles';
import content from './content';
import collaborations from './collaborations';
import microlearn from './microlearn';
import localforage from 'localforage';
import persistedState from './persistedState';
import { loadPersistedState } from './persistedState';
import {
	getSettingsToPersist,
	loadPersistedSettings
} from './settings';
import debounce from 'lodash/debounce';
import {
	default as bootstrap,
	BOOTSTRAP_SUCCESS,
	BOOTSTRAP_FAILURE
} from './bootstrap';
import * as Sentry from "@sentry/react";
import LogRocket from 'logrocket';

export default function configureStore(history, initialState) {
	const reducers = {
		audits,
		academics,
		activities,
		badges,
		bootstrap,
		businessProfiles,
		centres,
		channels,
		cloCourses,
		collaborations,
		content,
		communities,
		competencies,
		context,
		courses,
		dashboard,
		departments,
		discussion,
		events,
		experts,
		features,
		frameworks,
		insights,
		ipRegister,
		layout,
		loading,
		mentees,
		mentors,
		menu,
        messages,
		microlearn,
		modal,
		notifications,
		offers,
		organisations,
		passport,
		persistedState,
		positions,
		problems,
		problemChat,
		profiles,
		reports,
		resources,
		roles,
		settings,
		skillsProfiles,
		students,
		studentProjects,
		tenants,
		theme,
		themes,
		tools,
		unitsOfCompetency,
		userCommunities,
		userNotifications,
		users
	};

	const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
		history:  history
	});

	const middleware = [thunk, routerMiddleware];

	const enhancers = [];

	if (window.SkillsLogiq.SentryDSN) {
		const sentryReduxEnhancer = Sentry.createReduxEnhancer({
			// Optionally pass options
		});
		enhancers.push(sentryReduxEnhancer);
	}

	// // Use the browser's Redux dev tools extension if installed
	// if (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
	// 	enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
	// }

	const appReducer = combineReducers({ ...reducers, router: routerReducer });
	// const appReducer = combineReducers({ ...reducers, router: routerReducer });

	const rootReducer = (state, action) => {
		switch (action.type) {
			case BOOTSTRAP_SUCCESS:
				const data = action.data

				LogRocket.identify(data.userId, {
					name: data.userName,
					email: data.email,
					fullName: data.fullName,
					userName: data.userName,
					tenantId: data.tenantId,
					tenantTitle: data.tenantTitle,
					organisationId: data.organisationId,
					organisationTitle: data.organisationTitle,
					roles: JSON.stringify(data.roles || []),
					functions: JSON.stringify(data.functions || [])
				});
				
				Sentry.setUser({ 
					email: data.email,
					username: data.userName,
					tenantId: data.tenantId,
					tenantTitle: data.tenantTitle,
					organisationId: data.organisationId,
					organisationTitle: data.organisationTitle,
					roles: JSON.stringify(data.roles || [])
				});

				const theme = { ...state.theme };
				if (data.theme) {
					if (data.theme.primaryColour) theme.primaryColour = data.theme.primaryColour;
					if (data.theme.primaryColourText) theme.primaryColourText = data.theme.primaryColourText;
					if (data.theme.secondaryColour) theme.secondaryColour = data.theme.secondaryColour;
					if (data.theme.secondaryColourText) theme.secondaryColourText = data.theme.secondaryColourText;
					if (data.theme.tagColour) theme.tagColour = data.theme.tagColour;
					if (data.theme.tagColourText) theme.tagColourText = data.theme.tagColourText;
					if (data.theme.colourPalette) theme.colourPalette = data.theme.colourPalette;
				}

				return {
					...state,
					bootstrap: {
						loading: false,
						error: null
					},
					theme: theme,
					context: {
						...state.context,
						isLoggedIn: data.isLoggedIn,
						isRegistrationComplete: data.isRegistrationComplete,
						isPublicLink: data.isPublicLink,
						userId: data.userId,
						roles: data.roles,
						isDev: data.roles ? data.roles.includes("Dev") : false,
						isGlobalAdministrator: data.roles ? data.roles.includes("Global Administrator") : false,
						isTenantAdministrator: data.roles ? data.roles.includes("Tenant Admin") : false,
						isDepartmentAdministrator: data.roles ? data.roles.includes("Department Admin") : false,
						isAdministrator: data.roles ? data.roles.includes("Administrator") : false,
						isMember: data.roles ? data.roles.includes("Member") : false,
						email: data.email,
						fullName: data.fullName,
						firstName: data.firstName,
						lastName: data.lastName,
						userName: data.userName,
						avatar: data.avatar,
						location: data.location,
						tenantId: data.tenantId,
						tenantTitle: data.tenantTitle,
						canSelfRegister: data.canSelfRegister,
						organisationId: data.organisationId,
						organisationTitle: data.organisationTitle,
						organisationLogo: data.organisationLogo,
						organisationAccreditedLogo: data.organisationAccreditedLogo,
						organisationDefaultSkillsProfileId: data.organisationDefaultSkillsProfileId,
						initialAssessmentProfileId: data.initialAssessmentProfileId,
						departmentId: data.departmentId,
						position: data.position,
						lockCompetencyUpdates: data.lockCompetencyUpdates,
						hasCompetencies: data.hasCompetencies,
						showUserTour: data.showUserTour,
						userTour: data.userTour,
						subdomainId: data.subdomainId,
						registrationTenantId: data.tenantId,
						registrationOrganisationId: data.organisationId,
						registrationProcess: data.registrationProcess,
						organisationType: data.organisationType,
						canCreatePublicTool: data.CanCreatePublicTool,
						academicId: data.academicId,
						functions: data.functions || [],
						disableSecondaryProfile: data.disableSecondaryProfile,
						showMembershipLevelId: data.showMembershipLevelId,
						membershipLevelId: data.membershipLevelId,
						showReviewStatus: data.showReviewStatus,
						registerUserOrganisation: data.registerUserOrganisation,
						userOrganisation: data.userOrganisation,
						isSubscribed: data.isSubscribed,
						communityType: data.communityType,
						defaultProduct: data.defaultProduct,
						hasBusinessProfile: data.hasBusinessProfile,
						showSkillsPassportIntro: data.showSkillsPassportIntro,
						tenantHasCopyright: data.tenantHasCopyright,
						organisationSubscribes: data.organisationSubscribes,
						defaultAreaId: data.defaultAreaId,
						defaultSkillsFramework: data.defaultSkillsFramework,
						defaultSkillsProfileId: data.defaultSkillsProfileId,
						defaultPrimarySkillsProfileGroupId: data.defaultPrimarySkillsProfileGroupId,
						defaultSecondarySkillsProfileGroupId: data.defaultSecondarySkillsProfileGroupId,
						defaultInitialAssessmentProfileId: data.defaultInitialAssessmentProfileId
					},
					settings: {
						...state.settings,
						logo: data.logo,
						backgroundImage: data.backgroundImage,
						favicon: data.favicon,
						sectionOrder: data.sectionOrder ? JSON.parse(data.sectionOrder) : [],
						stripePublicApiKey: data.stripePublicApiKey,
						apsc_tenantId: data.apsC_TenantId,
						apsc_organisationId: data.apsC_OrganisationId,
						showPoweredBy: data.showPoweredBy,
						channels: data.channels || []
					},
					competencies: {
						...state.competencies,
						userHasCompetencies: data.userHasCompetencies,
						completeUserAssessment: data.completeUserAssessment,
						userAssessmentComplete: data.userAssessmentComplete
					}
				};
			case BOOTSTRAP_FAILURE:
				return { 
					...state, 
					bootstrap: {
						data: null,
						error: action.error,
						loading: false
					}
				};
			default:
				return appReducer(state, action);
		}
	}

	const store = reduxConfigureStore({
		reducer: rootReducer, 
		preloadedState: initialState, 
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([...middleware, LogRocket.reduxMiddleware()]),
		enhancers: (getDefaultEnhancers) => {
			return getDefaultEnhancers().concat(enhancers);
		}
	});

	const reduxHistory = createReduxHistory(store);

	localforage.config({
		driver      : localforage.IndexedDB,
		name        : 'SkillsLogiq',
		version     : 1.0,
		storeName   : 'SkillsLogiq', // Should be alphanumeric, with underscores.
		description : 'SkillsLogiq Local Data Storage'
	});

	localforage.getItem('persistedState')
		.then((value) => {
			store.dispatch(loadPersistedState(value));

			// Don't subscribe until after we load the state otherwise
			// we may overwrite the state before we load it
			store.subscribe(debounce(() => {
				localforage.setItem('persistedState', store.getState().persistedState);
			}, 20));
		});

	// Store settings so we can get default values without waiting for first network call to complete
	localforage.getItem('settings')
		.then((value) => {
			store.dispatch(loadPersistedSettings(value));

			// Don't subscribe until after we load the state otherwise
			// we may overwrite the state before we load it
			store.subscribe(debounce(() => {
				localforage.setItem('settings', getSettingsToPersist(store.getState().settings));
			}, 20));
		});

	return {
		store: store,
		reduxHistory: reduxHistory
	};
}
