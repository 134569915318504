import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import DownloadIcon from '@mui/icons-material/GetApp';
// import AcademicLogo from '../images/insights/A icon-01.svg';
// import IndustryLogo from '../images/insights/I icon.svg';
import TabContainer from '../widgets/TabContainer';
import Tab from '../widgets/Tab';
import { withStyles } from 'tss-react/mui';
import VideoPlayer from '../widgets/VideoPlayer';
import Card from '../widgets/Card';
import { LinkedInButton, WebsiteButton } from '../widgets/SocialMediaButtons';
import { 
	capabilityThemes
} from '../../store/microlearn';
import find from 'lodash/find';

const styles = (theme) => ({
});

const downloadAttachment = (microLearnId, attachmentId) =>
	window.open(`/MicroLearn/DownloadAttachment?microLearnId=${microLearnId}&attachmentId=${attachmentId}`, "_self");

const ViewMicroLearn = props => {
	const { microLearn } = props;
	const capabilityTheme = find(capabilityThemes, t => t.value === microLearn.capabilityTheme);
	const capabilityThemeImageUrl = capabilityTheme ? capabilityTheme.imageUrl : "";

	return props.loading || isEmpty(microLearn) ? <CircularProgress size={24} /> : (
		<Grid container spacing={1} className="microLearn-details">
			<Grid item xs={12} md={12} 
				style={{ 
					paddingBottom: 0, 
					paddingTop: '10px', 
					position: 'relative',
					display: 'flex',
					alignItems: 'center',
					gap: '5px'
				}}
			>
				{microLearn.organisationLogo && 
					<img 
						src={microLearn.organisationLogo}  
						alt="" 
						style={{ 
							maxHeight: '100px',
							maxWidth: '100px'
						}}
					/>
				}
				<span className="h1">{microLearn.title}</span>
			</Grid>

			<Grid item xs={12} md={8}>
				<TabContainer>
					<Tab label="Video">
						{microLearn.video &&
							<VideoPlayer 
								url={microLearn.video} 
								width="100%"
								height=""
							/>
						}
					</Tab>
					{microLearn.attachments && microLearn.attachments.length > 0 &&
					<Tab label="Attachments">
							<div className="row">
								<ul className="list-unstyled">
									{map(microLearn.attachments, (a, i) =>
										<li className="media" key={i}>
											<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
											<div className="media-body">
												<h5 className="mt-0 mb-1">{a.name}</h5>
												<p>{a.description}</p>
												<Button 
													variant="outlined" 
													startIcon={<DownloadIcon />}
													onClick={() => downloadAttachment(microLearn.microLearnId, a.microLearnAttachmentId)}>
													Download now
												</Button>
											</div>
										</li>
									)}
								</ul>
							</div>
						</Tab>

					}
				</TabContainer>
			</Grid>	


			<Grid item xs={12} md={4}>
				<Card className="contact-info">
					{(microLearn.image || capabilityThemeImageUrl) && 
						<div style={{ textAlign: 'center', marginBottom: '10px' }}>
							<img src={microLearn.image || capabilityThemeImageUrl} alt={microLearn.title} style={{ maxWidth: '100%' }} />
						</div>
					}

					<span className="h5">Type</span>
					{microLearn.type}

					<span className="h5" style={{ marginTop: 20 }}>Creator</span>
					{microLearn.creatorName}
					<span className="h5" style={{ marginTop: 20 }}>Institution</span>
					{microLearn.creatorInstitution}

					{(microLearn.creatorLinkedIn || microLearn.creatorProfileUrl) &&
						<div style={{ marginTop: 20 }}>
							<span className="h5">Social links</span>
							{microLearn.creatorLinkedIn && <LinkedInButton length={35} link={microLearn.creatorLinkedIn} />}
							&nbsp;
							{microLearn.creatorProfileUrl && <WebsiteButton length={35} link={microLearn.creatorProfileUrl} />}
						</div>
					}

					<p style={{ marginTop: 20 }}>{microLearn.summary}</p>
				</Card>
			</Grid>

			<Grid item xs={12}>
				{props.canEdit && <ButtonLink to={`/resources/edit/${microLearn.microLearnId}`}>Edit Resource</ButtonLink>}
			</Grid>
		</Grid>
	);
};

ViewMicroLearn.propTypes = {
	microLearn: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

export default withStyles(ViewMicroLearn, styles);